<template>
  <div
    v-if="!isLoading"
    class="p-5 d-flex flex-column justify-content-center align-items-center"
    style="height: 100vh !important"
  >
    <b-container class="my-5">
      <b-row class="border">
        <b-col class="text-center p-0">
          <div class="mb-3 mt-3 border-right">
            <feather-icon
              class="mx-2"
              color="#ea5455"
              size="160"
              icon="CheckCircleIcon"
            />
            <h1 class="mt-3">
              {{
                $t('thank_you', {
                  '1': delivery.user ? delivery.user.full_name : ''
                })
              }}
            </h1>
            <h4 class="mt-2">
              {{ $t('your_delivery_is_confirmed') }}
            </h4>
            <p class="mt-2">
              {{ $t('delivery_confirmed_message') }}
            </p>
            <b-button
              type="button"
              variant="primary"
              class="mt-3 mx-auto d-block"
              style="width: 212px"
              @click.prevent="downloadPdf('grn')"
            >
              <span v-if="!isDownloadingGrn">{{
                $t('print_good_return_note')
              }}</span>
              <b-spinner v-else small :label="$t('dowloading')" />
            </b-button>
            <b-button
              type="button"
              variant="primary"
              class="mt-1 mx-auto d-block"
              style="width: 212px"
              @click.prevent="downloadPdf('cover')"
            >
              <span v-if="!isDownloadingCover">{{
                $t('print_cover_note')
              }}</span>
              <b-spinner v-else small :label="$t('dowloading')" />
            </b-button>
            <b-button
              type="button"
              variant="primary"
              class="mt-1"
              style="width: 212px"
              to="/"
              >{{ 'Dashboard' }}</b-button
            >
          </div>
        </b-col>
        <b-col
          class="text-center d-flex flex-column align-items-center justify-content-center"
        >
          <div class="m-3">
            <b-container class="border">
              <b-row class="border">
                <b-col
                  md="12"
                  class="p-1 border d-flex flex-column align-items-center justify-content-center"
                >
                  <h2 class="mb-0">
                    {{ delivery.reference_number }}
                  </h2>
                  <h5 class="mb-0">Reference No.</h5>
                </b-col>
                <b-col class="p-1 border">
                  <h2 class="mb-0">
                    {{ $helpers.formatTotal(delivery.amount) }}
                  </h2>
                  <h5 class="mb-0">
                    {{ $t('amount') }}
                  </h5>
                </b-col>
                <b-col class="p-1 border">
                  <h2 class="mb-0">
                    {{ $helpers.formatDate(delivery.date_added) }}
                  </h2>
                  <h5 class="mb-0">
                    {{ $t('order_placed') }}
                  </h5>
                </b-col>
              </b-row>
            </b-container>
            <b-container class="mt-2 border">
              <b-row cols="1" class="border">
                <b-col class="p-1 border">
                  <h2 class="mb-0">
                    {{ $t('invoice') }}
                  </h2>
                  <span
                    class="text-secondary cursor-pointer"
                    @click.prevent="handleDownloadInvoice(delivery.id)"
                  >
                    Download Invoice.
                  </span>
                </b-col>
              </b-row>
            </b-container>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from '@axios'

export default {
  props: {
    referenceNo: {
      type: [String, Number],
      default: null
    },
    mop: {
      type: [String, Number],
      default: null
    }
  },
  data() {
    return {
      isLoading: false,
      isDownloadingGrn: false,
      isDownloadingCover: false,
      delivery: {}
    }
  },
  created() {
    this.fetchDeliveries()
  },
  methods: {
    async fetchDeliveries() {
      this.isLoading = true
      const response = await axios.get(
        `delivery-confirmed/${this.referenceNo}/${this.mop}`
      )
      if (response.status === 200) {
        this.delivery = response.data
      }
      this.isLoading = false
    },
    async downloadPdf(type) {
      if (this.delivery.order_type === 'CHARTERED') {
        let link = ''
        let fileName = ''
        if (type === 'inv') {
          link = `/download-invoice/${this.delivery.id}/SENANGPAY/chartered`
          fileName = `invoice-${this.delivery.id}`
        } else {
          link = `chartered-delivery-confirmed/${this.delivery.id}/SENANGPAY/print/${type}`
          fileName = `${type}-${this.delivery.id}`
        }
        const response = await this.$http.get(link, {
          responseType: 'blob'
        })
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute('download', `${fileName}.pdf`)
        document.body.appendChild(fileLink)
        fileLink.click()
      } else {
        let fileName = ''
        if (type === 'cover') {
          fileName = 'cover-note'
          this.isDownloadingCover = true
        }

        if (type === 'grn') {
          fileName = 'good-return-note'
          this.isDownloadingGrn = true
        }

        const response = await axios.get(
          `delivery-confirmed/${this.delivery.id}/${this.mop}/print/${type}`,
          { responseType: 'blob' }
        )
        const fileURL = window.URL.createObjectURL(new Blob([response.data]))
        const fileLink = document.createElement('a')

        fileLink.href = fileURL
        fileLink.setAttribute(
          'download',
          `${fileName}-${this.delivery.reference_number}.pdf`
        )
        document.body.appendChild(fileLink)

        fileLink.click()
        this.isDownloadingCover = false
        this.isDownloadingGrn = false
      }
    },
    async handleDownloadInvoice(paymentId) {
      let link = ''
      if (this.delivery.order_type === 'CHARTERED') {
        link = `/download-invoice/${paymentId}/${this.mop}/chartered`
      } else {
        link = `/download-invoice/${paymentId}/${this.mop}`
      }
      const response = await axios.get(link, {
        responseType: 'blob'
      })
      const fileURL = window.URL.createObjectURL(new Blob([response.data]))
      const fileLink = document.createElement('a')

      fileLink.href = fileURL
      fileLink.setAttribute(
        'download',
        `invoice-${
          this.delivery.transaction_id ?? this.delivery.reference_number
        }.pdf`
      )
      document.body.appendChild(fileLink)
      fileLink.click()
    }
  }
}
</script>
